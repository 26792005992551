import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'

import { AggregationFunctionEnum } from '@/filters/FiltersData'
import { UserColumnsSettings } from '@/patient-list/PatientListColumns'
import { PatientListColumn } from '@/store/modules/patient/types'
import { Variable } from '@/variables/types'

export enum SearchOperatorEnum {
  AND_OR = 'andOr',
  OR_AND = 'orAnd',
}
export enum OperatorEnum {
  AND = 'AND',
  OR = 'OR',
}
export const operatorLabels = {
  [OperatorEnum.AND]: 'ET',
  [OperatorEnum.OR]: 'OU',
}
export enum SearchTypeEnum {
  CRITERION = 'criterion',
  VARIABLE = 'variable',
}
export type Condition = {
  id: number | string
  type: string
  value: string
  aggregation?: AggregationFunctionEnum
}
export type ConditionGroup = {
  id: number | string
  conditionList: Condition[]
}
export interface SearchInterface {
  id: number
  name: string
  hash: string
  searchOperators: SearchOperatorEnum
  globalConditionGroup: ConditionGroup
  conditionGroups: ConditionGroup[]
  user: { id: number }
}
export type Criterion = SearchInterface & {
  snomedConceptId: string | null
  snomedConceptTerm: string | null
}

// todo: to be completed
export class SearchState extends AbstractState {
  currentSearch: Criterion | Variable = null
  isSearchEdited: false
  patientListColumns: { [key: string]: PatientListColumn }
  userColumnsSettings: UserColumnsSettings
}
