export enum FilterDataType {
  BIOLOGY = 'biology',
  COMPLETION = 'completion',
  CONSTANT = 'constant',
  DATE = 'date',
  EXPOSITION = 'exposition',
  MEDICAL_UNIT = 'medicalUnit',
  SELECT = 'select',
  RANGE = 'range',
  TEXT = 'text',
  TEXT_CONTENT = 'textContent',
  NONE = 'none',
  VARIABLE = 'variableResults',
}
export enum FilterValueType {
  SINGLE_VALUE = 'singleValue',
  MULTI_VALUE = 'multiValue',
}
export enum AggregateTypeEnum {
  COUNT = 'count',
  COUNT_STAY = 'count_stay',
  SUM = 'sum',
  VALUE_MIN_MAX = 'value_min_max',
  VALUE_AGGR = 'value_aggr',
}
interface FilterDataRange {
  less: boolean
  min: number
  interval: number
  max: number
  more: boolean
  unit: string
}

export interface FilterData {
  name: string
  type: FilterDataType
  aggregationType?: FilterDataType // override type in aggregation mode
  allowedInCondition?: boolean
  allowedAggregateTypes?: AggregateTypeEnum[]
  valueType?: FilterValueType
  label?: string
  completionName?: string
  format?: string
  aggregationFormat?: string // override format in aggregation mode
  isGlobalCondition?: string
  isGlobalOptions?: string[]
  notGlobalOptions?: string[]
  options?: string[]
  default?: string
  labels?: { [key: string | number]: string }
  range?: FilterDataRange
  subFilters?: FilterData[]
}
