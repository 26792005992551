/**
 * return null if json is not valid (instead of throwing an error)
 */
export function parseJson<T>(rawContent: string): T | null {
  try {
    return JSON.parse(rawContent)
  } catch {
    return null
  }
}
