import router from '@/router'

import Api from '../misc/api'
import { buildUrlWithParams } from '../misc/urlUtils'

const DEFAULT_BREADCRUMB_LIST = () => [{ name: 'Accueil', key: null, routerLinkArg: '/' }]
const DEFAULT_BREADCRUMB = () => {
  return { show: true, currentKey: null, list: DEFAULT_BREADCRUMB_LIST() }
}
const EMPTY_HOME_STATS = {
  countAllPatients: 0,
}
function loadBreadcrumb(stateBreadcrumb) {
  const storeBreadcrumb = sessionStorage.breadcrumb ? JSON.parse(sessionStorage.breadcrumb) : false
  const storeBcIsValid = storeBreadcrumb && storeBreadcrumb.list

  return storeBcIsValid ? storeBreadcrumb : stateBreadcrumb
}
function saveBreadcrumb(breadcrumb) {
  sessionStorage.setItem('breadcrumb', JSON.stringify(breadcrumb))
}

export default {
  state: {
    homeStats: EMPTY_HOME_STATS,
    breadcrumb: DEFAULT_BREADCRUMB(),
    getGlobalStatsRequest: { fetching: false, error: null, ok: false },
  },
  mutations: {
    getHomeStatsStart(state) {
      state.getHomeStatsRequest = { fetching: true, error: null, ok: false }
    },
    getHomeStatsSuccess(state, stats) {
      state.getHomeStatsRequest.fetching = false
      state.getHomeStatsRequest.ok = true
      state.homeStats = stats
    },
    getHomeStatsError(state, res) {
      state.getHomeStatsRequest.fetching = false
      state.getHomeStatsRequest.error = res.message
      state.homeStats = EMPTY_HOME_STATS
    },
    addBreadcrumbEntry(state, { name, customKey, routerLinkArg }) {
      // Take the customkey or create one based on part of the URL
      const key = customKey ?? (router.resolve(routerLinkArg).path ?? null)
      const currentBreadcrumb = loadBreadcrumb(state.breadcrumb)
      const newBreadcrumb = { name, key, routerLinkArg }

      // Creating the new breadcrumb list from store entry by entry until end or matching with current entry
      const newBreadcrumbList = []
      let added = false
      for (const [index, breadcrumb] of currentBreadcrumb.list.entries()) {
        if (breadcrumb.key === null) {
          newBreadcrumbList[index] = breadcrumb
          // Breadcrumb base entry, no further logic needed

          continue
        }
        if (breadcrumb.key === newBreadcrumb.key) {
          // Current entry match existing one, we are either navigating through the breadcrumb
          // or refreshing the last entry. Add the new entry and stop here, other store entries is ignored.
          newBreadcrumbList[index] = newBreadcrumb
          added = true
          break
        }
        // Otherwise, it's just a part of the breadcrumb, add it as usual
        newBreadcrumbList[index] = breadcrumb
      }
      if (!added) {
        // If the current entry was not added, add it at the end
        newBreadcrumbList.push(newBreadcrumb)
      }
      // Show and save the new list.
      state.breadcrumb.show = true
      state.breadcrumb.currentKey = key
      state.breadcrumb.list = newBreadcrumbList
      saveBreadcrumb(state.breadcrumb)
    },
    replaceLastBreadcrumbEntry(state, name) {
      state.breadcrumb.list[state.breadcrumb.list.length - 1].name = name
      saveBreadcrumb(state.breadcrumb)
    },
    setBreadcrumbShow(state, show) {
      state.breadcrumb.show = show
    },
    setBreadcrumbList(state, { defaultBreadcrumb, breadcrumbList }) {
      let breadcrumbListToSet
      if (defaultBreadcrumb !== false) {
        breadcrumbListToSet = DEFAULT_BREADCRUMB_LIST()
        breadcrumbListToSet.push(...breadcrumbList)
      }
      state.breadcrumb.show = true
      state.breadcrumb.list = breadcrumbListToSet
      saveBreadcrumb(state.breadcrumb)
    }
  },
  actions: {
    getHomeStats({ commit }, { earlyLimit, lateLimit }) {
      const params = {
        earlyLimit: earlyLimit !== null ? earlyLimit.format() : null,
        lateLimit: lateLimit !== null ? lateLimit.format() : null,
      }

      return Api.get(
        {
          url: buildUrlWithParams('/api/home-stats', params),
          startMut: 'getHomeStatsStart',
          successMut: 'getHomeStatsSuccess',
          errorMut: 'getHomeStatsError',
        },
        commit
      )
    },
  },
}
