import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'

import { VersionningPayload } from '@/store/types'
import { Variable } from '@/variables/types'

export interface VariablesJson extends VersionningPayload {
  searches: Variable[]
  type: 'variable'
}

export class VariableState extends AbstractState {
  selectedVariableIds: number[] = []
}
