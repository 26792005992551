import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'

import { User } from '@/settings/types'
import { Condition, OperatorEnum } from '@/store/modules/search/types'
import { emptyStudy } from '@/store/modules/study/utils'
import { Variable } from '@/variables/types'

export enum StudyPrivacy {
  PRIVATE_STUDY = 'private',
  PUBLIC_STUDY = 'public',
}
export type DiagramProperties = {
  x: number
  y: number
}
export interface DiagramNode {
  id: number | string
  level: number
  operator: OperatorEnum
  children: StudyNode[]
  diagramProperties: DiagramProperties
}

export type Foundation = {
  id: number
  dataGeneratedId: number
  label: string
}

export type Investigator = {
  id: number
  firstName: string
  lastName: string
}

export interface StudyNode extends DiagramNode {
  name: string
  conditionList: Condition[]
}
export type StudyStep = {
  id: number
  name: string
  position: number
  isCore: boolean
  rootNode: StudyNode
}
export type Study = {
  id: number
  name: string
  reference: string
  privacy: StudyPrivacy
  foundation: Foundation | null
  disabled: boolean
  steps: StudyStep[]
  user: User
  countPatient?: number
  validatedAt: string | null
  studyUsers: User[]
  variables: Variable[]
}

export interface StudyVariable {
  id: number
  name: string
  isAggregated: boolean
}

export type StudyStats = {
  patientCount: number
  stats: {
    medicalUnits: { patientCount: number, medicalUnit: string }[]
    patientAge: { patientCount: number, patientAge: string }[]
    gender: { patientCount: number, gender: string }[]
  }
}

// todo: to be completed
export class StudyState extends AbstractState {
  currentStudy: Study = emptyStudy()
  currentStudyStep: StudyStep = null
  currentStudyStats: StudyStats = null
  investigators: Investigator[] = []
  foundations: Foundation[] = []
}
