import Api from '@sancare/ui-frontend-commons/src/misc/api'
import { toRaw } from 'vue'

import { asyncPatientParsing, terminateWorker } from '@/misc/TextWorkerHelper'
import { Patient } from '@/store/modules/patient/types'
import { Criterion } from '@/store/modules/search/types'
import { Study, StudyNode, StudyStep } from '@/store/modules/study/types'
import { convertCriterionToStudyNode } from '@/study/utils.js'
import { Variable } from '@/variables/types'

import { state } from './state'

interface LaunchTextWorkerPayload {
  patient: Patient
  study?: Study
  studyStep?: StudyStep
  studyNode?: StudyNode
  search?: Criterion
  matchingStayIds: number[]
  variables: Variable[]
}

export const actions = {
  getOnePatient({ commit }, ipp: string) {
    return Api.request(state.requests.getOnePatient, {
      urlParams: { ipp },
      successMut: 'getOnePatientSuccess',
    }, commit)
  },
  launchTextWorker({ commit }, payload: LaunchTextWorkerPayload) {
    let studyNodes = []
    let variables = []
    if (payload.study) {
      studyNodes = payload.study.steps.map((step) => {
        return toRaw(step.rootNode)
      })
    }
    if (payload.studyStep) {
      studyNodes.push(toRaw(payload.studyStep.rootNode))
    }
    if (payload.studyNode) {
      studyNodes.push(toRaw(payload.studyNode))
    }
    if (payload.search) {
      studyNodes.push(convertCriterionToStudyNode(toRaw(payload.search), 1, { x:0, y:0 }))
    }
    if (payload.variables) {
      variables = payload.variables.map((variable) =>
        convertCriterionToStudyNode(toRaw(variable), 1, { x:0, y:0 }, variable.id)
      )
    }

    try {
      commit('setTextWorkerReady', false)

      // toRaw is used because only simple objects can be parsed by worker postMessage
      const rawPatient = toRaw(payload.patient)

      // send matching stays only to text-worker
      asyncPatientParsing(rawPatient.stays, rawPatient.stays.filter((s) => payload.matchingStayIds.includes(s.id)).map((s) => s.id), studyNodes, variables).then((result) => {
        commit('setCurrentPatient', { ...payload.patient, stays: result.stays })
        commit('setStudyNodeMatches', result.studyNodeMatches)
        commit('setVariableMatches', result.variableMatches)
        // we have to manualy refresh current patient,stay,document as we temporarily lost reactivity (toRaw)
        commit('refreshCurrentStay')
        commit('refreshCurrentDocument')
        commit('setTextWorkerReady', true)
      })
    } catch (e) {
    // pass
    }
  },
  terminateTextWorker() {
    terminateWorker()
  }
}
