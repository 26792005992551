/**
 * keep in sync with StudyVoter.php
 */
import { User } from '@/settings/types'
import { Study, StudyPrivacy } from '@/store/modules/study/types'

import { hasPermission, RoleEnum } from './permissionsHelper'

// you cannot be investigator of an unvalidated study
function isInvestigator(study: Study, user: User): boolean {
  return study.validatedAt !== null && Boolean(study.studyUsers.find((u) => u.id === user.id))
}
function isOwner(study: Study, user: User): boolean {
  return study.user?.id === user.id
}

export default {
  canAttachUser: (study: Study, user: User): boolean => {
    return hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role)
  },
  canCopy: (study: Study, user: User): boolean => {
    return study.privacy === StudyPrivacy.PUBLIC_STUDY || isOwner(study, user)
  },
  canCreate: (user: User): boolean => {
    return hasPermission(RoleEnum.ROLE_STUDY_CREATOR, user.role)
  },
  canEditName: (study: Study, user: User): boolean => {
    return hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role) || isOwner(study, user)
  },
  canEditPrivacy: (study: Study, user: User): boolean => {
    return hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role)
  },
  canEditFoundation: (study: Study, user: User): boolean => {
    return hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role)
  },
  canEditCoreStep: (study: Study, user: User): boolean => {
    return study.validatedAt === null
      && (hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role) || isOwner(study, user))
  },
  canEditSteps: (study: Study, user: User): boolean => {
    return hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role)
      || (
        study.validatedAt !== null && isInvestigator(study, user)
      )
  },
  canEnable: (study: Study, user: User): boolean => {
    return study.disabled && hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role)
  },
  canExport: (study: Study, user: User): boolean => {
    return hasPermission(RoleEnum.ROLE_STUDY_EXPORT_IMPORT, user.role)
  },
  canDisable: (study: Study, user: User): boolean => {
    return !study.disabled && hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role)
  },
  canDelete: (study: Study, user: User): boolean => {
    return study.validatedAt === null 
        && (hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role) || isOwner(study, user))
  },
  canShowPatientList: (study: Study, user: User): boolean => {
    return hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role) || isInvestigator(study, user)
  },
  canShow: (study: Study, user: User): boolean => {
    return study.privacy === StudyPrivacy.PUBLIC_STUDY
      || hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role)
      || isOwner(study, user)
      || isInvestigator(study, user)
  },
  canValidate: (study: Study, user: User): boolean => {
    return study.validatedAt === null 
      && study.steps.length > 0 
      && study.studyUsers.length > 0
      && hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role)
  },
  canUnvalidate: (study: Study, user: User): boolean => {
    return study.validatedAt !== null && hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role)
  },
  canUpdateVariables: (study: Study, user: User): boolean => {
    return hasPermission(RoleEnum.ROLE_STUDY_ADMIN, user.role) || isInvestigator(study, user)
  },
}
