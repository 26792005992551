import { AbstractState } from '@sancare/ui-frontend-commons/src/store/types'

import { Criterion } from '@/store/modules/search/types'
import { VersionningPayload } from '@/store/types'

export interface CriteriaJson extends VersionningPayload {
  searches: Criterion[]
  type: 'criterion'
}

export class CriterionState extends AbstractState {
  selectedCriteriaIds: number[] = []
}
