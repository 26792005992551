import { setRequest } from '@sancare/ui-frontend-commons/src/misc/api'

import { Foundation, Investigator, StudyState } from '@/store/modules/study/types'

export const mutations = {
  setFoundations(state: StudyState, response: Foundation[]) {
    state.foundations = response
  },
  setInvestigators(state: StudyState, response: Investigator[]) {
    state.investigators = response
  },
  setRequest
}
