import _ from 'lodash'
/**
 * utils function to deal with study data structure
 */

// used for auto position
const stepAreaHeight = 200
const conditionIdPrefix = 'condition'
const studyNodeIdPrefix = 'node'
const stepIdPrefix = 'step'

export function uniqueId(prefix) {
  return _.uniqueId(prefix)
}

// studyNode is studyNode in which the search is done
// studyNodeId is the wanted studyNode id
function findStudyNodeRecursive(studyNode, studyNodeId) {
  if(studyNode.id === studyNodeId){
    return studyNode
  }
  for(const child of studyNode.children){
    const found = findStudyNodeRecursive(child, studyNodeId)
    if(found){
      return found
    }
  }
  return null
}
function findElementInStudyFromStudyNode(study, studyNodeId, returnStep) {
  for(const step of study.steps){
    const studyNode = findStudyNodeRecursive(step.rootNode, studyNodeId)
    if(studyNode){
      return returnStep ? step : studyNode
    }
  }
  return null
}
function findStudyNodeInStudy(study, studyNodeId) {
  return findElementInStudyFromStudyNode(study, studyNodeId, false)
}
function findStepFromStudyNode(study, studyNodeId) {
  return findElementInStudyFromStudyNode(study, studyNodeId, true)
}

function findParentRecursive(studyNode, sChild) {
  const found = studyNode.children.find((c) => c.id === sChild.id)
  if (found) {
    return studyNode
  }
  for(const child of studyNode.children){
    const found = findParentRecursive(child, sChild)
    if(found){
      return found
    }
  }
  return null
}
function findParentInStudy(study, child) {
  for(const step of study.steps){
    if (child.level && child.level === 1 && step.rootNode.id === child.id) {
      return step
    }
    const studyNode = findParentRecursive(step.rootNode, child)
    if(studyNode){
      return studyNode
    }
  }
  return null
}

function findExtremYRecursive(studyNode, max) {
  const maxes = []
  for(const child of studyNode.children){
    maxes.push(child.diagramProperties.y)
    if (!child.name) {
      maxes.push(findExtremYRecursive(child, max))
    }
  }
  return max ? Math.max(...maxes) : Math.min(...maxes)
}
function findMaxStepY(step) {
  const maxes = [step.rootNode.diagramProperties.y]
  maxes.push(findExtremYRecursive(step.rootNode, true))
  return Math.max(...maxes)
}
function findMinStepY(step) {
  const maxes = [step.rootNode.diagramProperties.y]
  maxes.push(findExtremYRecursive(step.rootNode, false))
  return Math.min(...maxes)
}

function translateYRecursive(studyNode, offsetY) {
  for(const child of studyNode.children){
    child.diagramProperties.y += offsetY
    if (!child.name) {
      translateYRecursive(child, offsetY)
    }
  }
}
function translateStepY(step, offsetY, doNotTranslateStep) {
  if (!doNotTranslateStep) {
    step.rootNode.diagramProperties.y += offsetY
  }
  translateYRecursive(step.rootNode, offsetY)
}

function updateStepsPositions(steps, newPositions) {
  const minHeight = 125
  const initOffsets = []
  let i = 0
  for (const step of steps) {
    const minY = findMinStepY(step)
    const maxY = findMaxStepY(step)
    const data = {
      id: step.id,
      minY,
      maxY,
      height: maxY - minY || minHeight,
    }
    initOffsets.push(data)
    i++
  }

  let offsetY = initOffsets[0].minY
  i = 0
  for (const newPosition of newPositions) {
    const step = steps.find((s) => s.id === newPosition.id)
    step.position = i
    i++

    const offsetData = initOffsets.find((o) => o.id === newPosition.id)
    const stepOffset = offsetY - (offsetData.minY)
    if(stepOffset !== 0) {
      translateStepY(step, stepOffset)
    }
    offsetY += offsetData.height + (minHeight / 2)
  }
  return _.sortBy(steps, ['position'])
}

function updateDiagramChildrenPositions(studyNode, diagram) {
  for (const child of studyNode.children) {
    diagram.updateStudyNodePosition(child)
    updateDiagramChildrenPositions(child, diagram)
  }
}

function updateAllDiagramPositions(study, diagram) {
  for (const step of study.steps) {
    diagram.updateStepPosition(step)
    updateDiagramChildrenPositions(step.rootNode, diagram)
  }
}

// transform criterion to study nodes with the following structure
// OPERATOR (AND|OR)
//   |-->softAbsentContent conditionList
//   |-->CONDITION NODES
//         |-->conditionList
function convertCriterionToStudyNode(criterion, initLevel, newNodePosition, fixedId = null) {
  const node = {
    id: fixedId ?? uniqueId(studyNodeIdPrefix),
    name: criterion.name,
    operator: criterion.searchOperators === 'andOr' ? 'AND' : 'OR',
    level: initLevel,
    conditionList: [],
    children: [],
    diagramProperties: newNodePosition,
  }
  const resultNode = node

  if (criterion.globalConditionGroup && criterion.globalConditionGroup.conditionList && criterion.globalConditionGroup.conditionList.length > 0) {
    for (const condition of criterion.globalConditionGroup.conditionList) {
      resultNode.conditionList.push({
        id: null,
        type: condition.type,
        value: condition.value,
      })
    }
  }

  for (const conditionGroup of criterion.conditionGroups) {
    const subNode = {
      id: fixedId,
      name: null,
      operator: criterion.searchOperators === 'andOr' ? 'OR' : 'AND',
      level: initLevel + 1,
      conditionList: [],
      children: [],
      diagramProperties: { x: 0, y: 0 },
    }

    for (const condition of conditionGroup.conditionList) {
      subNode.conditionList.push({
        id: null,
        type: condition.type,
        value: condition.value,
      })
    }
    node.children.push(subNode)
  }

  return resultNode
}
function convertStudyNodeToSearch(studyNode) {
  const search = {
    id: null,
    name: studyNode.name,
    searchOperators: studyNode.operator === 'AND' ? 'andOr' : 'orAnd',
    globalConditionGroup: {
      id: null,
      conditionList: []
    },
    conditionGroups: []
  }

  for (const condition of studyNode.conditionList) {
    search.globalConditionGroup.conditionList.push({
      id: condition.id,
      type: condition.type,
      value: condition.value,
    })
  }

  for (const child of studyNode.children) {
    const conditionGroup = {
      id: child.id,
      conditionList: [],
    }
    for (const condition of child.conditionList) {
      conditionGroup.conditionList.push({
        id: condition.id,
        type: condition.type,
        value: condition.value,
      })
    }
    search.conditionGroups.push(conditionGroup)
  }

  return search
}

function computeNewStepPosition(diagram, newStepHtml, lastStep) {
  const diagramSize = diagram.getPaperSize()
  const maxY = lastStep ? findMaxStepY(lastStep) : 0
  return {
    x: (diagramSize.width / 2) - (newStepHtml.clientWidth / 2),
    y: (1.5 * stepAreaHeight - (newStepHtml.clientHeight / 2) + maxY)
  }
}

function resetStudyNodeIds(studyNode) {
  studyNode.id = uniqueId(studyNodeIdPrefix)
  for (const condition of studyNode.conditionList) {
    condition.id = uniqueId(conditionIdPrefix)
  }
  for (const child of studyNode.children) {
    resetStudyNodeIds(child)
  }
}

export {
  computeNewStepPosition,
  convertCriterionToStudyNode,
  convertStudyNodeToSearch,
  findParentInStudy,
  findStudyNodeInStudy,
  findStepFromStudyNode,
  resetStudyNodeIds,
  translateStepY,
  updateAllDiagramPositions,
  updateStepsPositions,
  conditionIdPrefix,
  studyNodeIdPrefix,
  stepIdPrefix,
}
